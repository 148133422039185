<template>
  <div>
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考试监管</a>
        </span>
      </div>

      <div class="framePage-body">
        <!-- 查询 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:</span>
              <el-input
                size="small"
                clearable
                placeholder="请输入考试名称"
                v-model="examName"
              ></el-input>
            </div>
            <div title="考试时间" class="searchboxItem ci-full">
              <span class="itemLabel">考试时间:</span>
              <el-date-picker
                size="small"
                v-model="theoryTestTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="考试开始日期"
                end-placeholder="考试结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >搜索</el-button
            >
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <!-- <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              /> -->
              <el-table-column
                label="考试名称"
                align="left"
                width="200"
                prop="examName"
                show-overflow-tooltip
              />
              <el-table-column
                label="考场"
                align="left"
                prop="roomId"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  第{{ scope.row.roomId }}考场
                </template>
              </el-table-column>
              <el-table-column
                label="考试时间"
                align="center"
                width="260"
                show-overflow-tooltip
              >
                <template slot-scope="scope"
                  >{{ scope.row.theoryTestStart | moment }} ~
                  {{ scope.row.theoryTestEnd | moment }}</template
                >
              </el-table-column>
              <el-table-column
                label="考场人数"
                align="left"
                prop="actualExamNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="答题进行中人数"
                width="110"
                align="left"
                prop="inProgressNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="已交卷人数"
                align="left"
                prop="submittedNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="未开始人数"
                align="left"
                prop="notStartedNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="距结束时间(分钟)"
                width="150"
                align="left"
                prop="betweenEnd"
                show-overflow-tooltip
              />
              <el-table-column
                label="考试状态"
                align="left"
                prop="examStatus"
                show-overflow-tooltip
              />
              <el-table-column
                label="操作"
                align="center"
                width="220"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="InvigilateTeacher('10', scope.row)"
                    >监考老师</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="InvigilateTeacher('20', scope.row)"
                    >监督人员</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLookStudent(scope.row)"
                    >查看人员</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <!-- 监考老师 || 监督人员 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      center
      top="2%"
      @close="setCancel"
    >
      <div class="ovy-a" style="height: 500px">
        <div class="df form-box" v-for="(item, index) in addList" :key="index">
          <el-form
            :model="item"
            ref="cardform"
            label-width="120px"
            style="width: 80%"
            :disabled="invigilatorType == '20'"
          >
            <el-form-item :label="'姓名' + (index + 1) + ':'">
              <!-- <el-input
                size="small"
                placeholder="请输入姓名"
                v-model="item.userName"
              ></el-input> -->
              <el-select
                v-model="item.evaluateExamTeacherId"
                filterable
                remote
                size="small"
                placeholder="请输入"
                :remote-method="remoteMethod"
                @change="
                  (val) => {
                    remoteChange(val, index);
                  }
                "
              >
                <el-option
                  v-for="item in options"
                  :key="item.evaluateExamTeacherId"
                  :label="item.name"
                  :value="item.evaluateExamTeacherId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="'联系电话' + (index + 1) + ':'">
              <el-input
                size="small"
                placeholder="请输入联系电话"
                v-model="item.mobile"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="df zsBtn" v-if="invigilatorType == '10'">
            <i
              class="el-icon-circle-plus iconStyle"
              v-if="index == addList.length - 1"
              @click="certificate"
            ></i>
            <i
              class="el-icon-remove iconStyle"
              v-if="addList.length > 1"
              @click="delCertificate(index)"
            ></i>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer flexcc"
        v-if="invigilatorType == '10'"
      >
        <el-button @click="setCancel">取 消</el-button>
        <el-button @click="setSure" class="bgc-bv" type="primary"
          >确 定</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer flexcc" v-else>
        <el-button @click="setCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";
import Empty from "@/components/Empty.vue";

export default {
  components: {
    PageNum,
    Empty,
  },
  mixins: [List],
  data() {
    return {
      examName: "", //考试名称
      theoryTestTime: "", //考试名称
      //监考老师||监督人员
      dialogTitle: "", //
      dialogVisible: false,
      addList: [], // 新增列表
      addObj: {
        evaluateExamTeacherId: "", //姓名
        mobile: "", //电话
      },
      options: [],
    };
  },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {
    this.getData();
    this.addList.push({ ...this.addObj });
  },
  methods: {
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 鉴定考试时间
      if (this.theoryTestTime) {
        params.theoryTestStart = this.theoryTestTime[0];
        params.theoryTestEnd = this.theoryTestTime[1];
      }
      if (this.examName) {
        params.examName = this.examName;
      }
      this.doFetch({
        url: "/biz/exam/supervision/examSupervisionList",
        params,
        pageNum,
      });
    },
    //监考老师 || 监督人员
    InvigilateTeacher(invigilatorType, row) {
      if (invigilatorType == "20" && row.hasSupervisor) {
        this.getSupervisionInfo(row.roomId, invigilatorType, row.examinationId);
      }
      if (invigilatorType == "10" && row.hasInvigilator) {
        this.getSupervisionInfo(row.roomId, invigilatorType, row.examinationId);
      }
      this.dialogTitle =
        invigilatorType == "10" ? "设置监考老师" : "查看监督人员";
      this.dialogVisible = true;
      this.invigilatorType = invigilatorType;
      this.roomId = row.roomId;
      this.examinationId = row.examinationId;
    },
    // 设置监考老师 || 监督人员 --新增
    certificate() {
      this.addList.push({ ...this.addObj });
    },
    // 设置监考老师 || 监督人员  --删除
    delCertificate(ind) {
      this.addList.splice(ind, 1);
    },
    getSupervisionInfo(roomId, invigilatorType, examinationId) {
      let parameter = {
        roomId: roomId,
        invigilatorType: invigilatorType,
        examinationId: examinationId,
      };
      this.$post("/biz/exam/supervision/getInfo", parameter).then((res) => {
        this.addList = res.data || [];
        this.addList.map(el => {
          el.evaluateExamTeacherId = el.userId
          el.name = el.userName
          this.getTeacherId(el.userId)
        })
        
      });
    },
    //老师姓名回显
    getTeacherId(userId) {
      this.$post('/biz/evaluate/evaluateExamTeacher/getInfoById',{evaluateExamTeacherId:userId}).then(ret => {
        this.options.push(ret.data)
      })
    },
    remoteMethod(query) {
      this.$post("/biz/evaluate/evaluateExamTeacher/getTeacherInfo", {
        name: query,
        teacherType: "10",
      }).then((ret) => {
        this.options = ret.data;
      });
    },
    remoteChange(val, index) {
      console.log(val, index);
      let obj = {};
      obj = this.options.find((item) => {
        return item.evaluateExamTeacherId == val; //筛选出对应数据
      });
      this.addList[index].mobile = obj.mobile;
      this.addList[index].name = obj.name;
      // let name = this.options.map(el => el.evaluateExamTeacherId == val  return el.name
    },
    //监考考试 || 监督人员 -- 确认
    setSure() {
      for (let i = 0; i < this.addList.length; i++) {
        if (
          this.addList[i].evaluateExamTeacherId == "" ||
          this.addList[i].mobile == ""
        ) {
          this.$message({
            message: "姓名和电话不能为空",
            type: "error",
          });
          return false;
        }
      }
      let list = [];
      for (let i = 0; i < this.addList.length; i++) {
        list.push({
          invigilatorType: this.invigilatorType,
          mobile: this.addList[i].mobile,
          userId: this.addList[i].evaluateExamTeacherId,
          userName: this.addList[i].name,
          roomId: this.roomId,
          examinationId: this.examinationId,
          supervisionId: this.addList[i].supervisionId || "",
        });
      }
      let params = {
        list,
        roomId: this.roomId,
        examinationId: this.examinationId,
        invigilatorType: this.invigilatorType,
      };
      this.$post("/biz/exam/supervision/saveOrUpdate", params).then((res) => {
        if (res.status == 0) {
          this.setCancel();
          this.getData(-1);
          this.$message({
            type: "success",
            message: "设置成功",
          });
        }
      });
    },
    setCancel() {
      this.dialogVisible = false;
      this.dialogTitle = "";
      this.addList = [{}];
      this.options =[]
    },
    //查看学员
    handleLookStudent() {
      this.$message({
        message: "此功能暂未开发!",
        type: "warning",
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scope>
.zsBtn {
  margin-left: 20px;
  button {
    height: 30px;
  }
}
.iconStyle {
  font-size: 2rem;
  color: #6676ff;
  cursor: pointer;
}
</style>
